import React from "react";
import img1 from "../assests/funi o.webp";
import img2 from "../assests/emelda n.webp";
import img3 from "../assests/herinet o.webp";
import "./sixthpage.css";
import { FaInstagram, FaFacebookF, FaYoutube, FaTiktok } from "react-icons/fa";
import Img4 from "../assests/IMG_0178 (1).png";
import { FaArrowRight } from "react-icons/fa";

const sixthpage = () => {
  const faqs = [
    {
      question: "Is this program suitable for my skin type?",
      answer:
        "Absolutely! My program is tailored for anyone over 30, focusing on the unique needs of nature skin, and is inclusive for all races and skin types.",
    },
    {
      question: "How will I receive my workbook?",
      answer:
        "After you sign up, you’ll receive an email with the download link to your workbook.",
    },
  ];
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h1
        style={{
          maxWidth: "500px",
          padding: "2rem",
          paddingTop: "2rem",
          display: "block",
          textAlign: "center",
        }}
      >
        Testimonials/ Social Proof{" "}
      </h1>

      <div className="testimonials-container">
        <div className="testimonial-card">
          <img src={img1} alt="Funi O" id="profile-image" />
          <h3>Funi O</h3>
          <p>
            “An excellent workbook! The layout is clean and distraction-free,
            which makes it easy to follow. It’s simple to read, flows well, and
            covers all the essentials. I’ve noticed a real difference in my skin
            by consistently applying the principles. Can’t wait to join the 3
            months programme. Thank you!”
          </p>
        </div>

        <div className="testimonial-card">
          <img src={img2} alt="Emily R" id="profile-image" />
          <h3>Emelda N</h3>
          <p>
            “The basics are nailed—from pinpointing skin concerns to setting
            achievable timelines. It’s been such a boost of encouragement and
            motivation to crush my skincare goals! Love, love, love it!”
          </p>
        </div>

        <div className="testimonial-card">
          <img src={img3} alt="Harriet A" id="profile-image" />
          <h3>Harriet A</h3>
          <p>
            “As a medical doctor and beauty enthusiast, I can’t stress enough
            how transformative good skincare can be for your self-confidence and
            mental well-being. This workbook is a great start, and the 3-month
            course takes it to the next level!”
          </p>
        </div>
      </div>

      <div className="container">
        <div className="text-container">
          <h5
            style={{
              display: "inline-block",
              backgroundColor: "fff",
              borderRadius: "20px",
              border: "1px solid #ffa",
            }}
          >
            Who we are?
          </h5>
          <h1>Disvover More About Luxeskin by Poshmom</h1>
          <p>
            At LuxeSkin by Poshmom, I celebrate the beauty of aging with grace.
            My program is designed to empower you with effective strategies and
            support, focusing on rejuvenating and enhancing your skin’s natural
            beauty
          </p>
          <div className="social-media-icons">
            <a
              href="https://www.tiktok.com/@luxeskinbyposhmom"
              // target="_blank"
              rel="noopener noreferrer"
            >
              <FaTiktok className="icon" />
            </a>
            <a
              href="https://www.instagram.com/luxeskinbyposhmom/profilecard/?igsh=MWdvNGMwOG04ZW9sNQ=="
              // target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="icon" />
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=61568085341063&mibextid=LQQJ4d"
              // target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF className="icon" />
            </a>
            <a
              href="https://www.youtube.com/@Luxeskinbyposhmom"
              // target="_blank"
              rel="noopener noreferrer"
            >
              <FaYoutube className="icon" />
            </a>
          </div>

          <button className="btn-primary" style={{ margin: "1rem" }}>
            <a
              href="https://luxeskinbyposhmom.kit.com/e8a1419754"
              style={{ color: "#fff" }}
            >
              Download Your Free Skincare Workbook Worth £99!
              <FaArrowRight style={{ color: "white", paddingTop: "8px" }} />
            </a>
          </button>
        </div>
        <div className="image-container">
          <img src={Img4} alt="Poshmom" className="profile-image" />
        </div>
      </div>

      <h1
        style={{
          maxWidth: "520px",
          padding: "2rem",
          paddingTop: "6rem",
          display: "block",
          textAlign: "center",
          paddingBottom: "0",
        }}
      >
        Exclusive Offer: Download Your FREE Workbook Worth £99!
      </h1>
      <p
        style={{
          maxWidth: "600px",
          padding: "1rem",
          display: "block",
          textAlign: "center",
          lineHeight: "2rem",
          fontSize: "1.3rem",
          marginBottom: "1rem",
        }}
      >
        Don’t let the fear of aging dictate your beauty journey. Download your
        FREE workbook, valued at £99, now and start your journey to glowing,
        confident skin!
      </p>
      <button className="btn-primary">
        <a
          href="https://luxeskinbyposhmom.kit.com/e8a1419754"
          style={{ color: "#fff" }}
        >
          Get my free Workbook
        </a>
      </button>
      <div className="faq-container1">
        <h1
          style={{
            maxWidth: "520px",
            padding: "2rem",
            paddingTop: "6rem",
            display: "block",
            textAlign: "center",
            paddingBottom: "0",
          }}
        >
          Frequently Asked Questions
        </h1>
        {faqs.map((faq, index) => (
          <div key={index} className="faq-card1">
            <h3 className="faq-question1">{faq.question}</h3>
            <p className="faq-answer1">{faq.answer}</p>
          </div>
        ))}
      </div>
      <h1
        style={{
          maxWidth: "520px",
          padding: "2rem",
          display: "block",
          textAlign: "center",
          paddingBottom: "0",
        }}
      >
        Take the Leap: Your Glowing Transformation Begins Now!!!
      </h1>
      <p
        style={{
          maxWidth: "600px",
          padding: "1rem",
          display: "block",
          textAlign: "center",
          lineHeight: "2rem",
          fontSize: "1.3rem",
          marginBottom: "1rem",
        }}
      >
        Join a community of empowered individuals who are taking charge of their
        skincare journey. Your best, glowing skin is just a click away—embrace
        it with confidence!
      </p>

      <div
        className="faq-container1 faq-card1"
        style={{ marginBottom: "8rem" }}
      >
        <h2 style={{ alignItems: "center", fontSize: "20px" }}>Disclaimer</h2>
        <p style={{ fontSize: "12px" }}>
          General Disclaimer:
          <br /> The information provided on this skincare program is for
          educational purposes only and is not intended as medical advice.
          Always consult a healthcare professional before starting any new
          skincare regimen or program.
          <br />
          <br />
          Results Disclaimer:
          <br />
          Results may vary from person to person. While many individuals may
          experience positive changes in their skin, individual results will
          depend on various factors, including skin type, adherence to the
          program, and personal health.
          <br />
          <br />
          Important Note on Results
          <br />
          Testimonials shared are based on individual experiences and may not
          reflect the outcomes you will achieve. Consistent effort and
          commitment to the process are essential to see meaningful results.
          <br />
          <br />
          Personal Responsibility:
          <br />
          By using this program, you acknowledge that you are responsible for
          your own skincare choices and results. The effectiveness of the
          program may vary based on individual circumstances.
        </p>
      </div>
    </div>
  );
};

export default sixthpage;
